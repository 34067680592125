import PropTypes from "prop-types";
import React from "react";

type Social = { name: 'Twitch' | 'Youtube' | 'Twitter', url: string };

const socials: Social[] = [
	{ name: 'Twitch', url: 'https://www.twitch.tv/papersnchat' },
	{ name: 'Youtube', url: 'https://www.youtube.com/channel/UCX0-MQyhJJ0GU9fJom3eknQ' },
	{ name: 'Twitter', url: 'https://twitter.com/_bogthe' },
];

const SocialButtons = () => (
	<ul>
		{socials.map(({ name, url }, i) => (
			<li key={i} style={{
				display: 'inline-block',
				margin: '1.5rem 0',
				paddingRight: '3px',
			}}>
				<a style={{
					color: '#DF5202',
					textDecoration: 'none',
					fontFamily: 'Roboto Slab',
				}} href={url}>
					{name} {i === socials.length - 1 ? '' : '|'}
				</a>
			</li>)
		)}
	</ul>
);

const Header = ({ siteTitle }) => {
	return (
		<header
			style={{
				margin: `1.45rem`,
			}}
		>
			<div
				style={{
					margin: `0 auto`,
					maxWidth: 960,
					padding: `1.45rem 1.0875rem`,
					textAlign: 'center',
				}}
			>

				<h1 style={{ margin: 0, fontSize: '4rem', fontWeight: 200 }}>
					{siteTitle}
				</h1>

				<SocialButtons />
			</div>
		</header>
	);
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
